var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"d3ca8dae8944cbb3dccd92401a9f798c35f5d7e6"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

if (process.env.NODE_ENV === 'production') {
  import('@sentry/browser').then((Sentry) => {
    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      release: process.env.NEXT_PUBLIC_BUILD_TIME,
      attachStacktrace: true,
      environment:
        typeof window !== 'undefined'
          ? window.location.hostname.includes('bydfi.com')
            ? 'production'
            : 'development'
          : 'development',
      denyUrls: [/^chrome-extension:\/\//i],
      ignoreErrors: [],
      // Adjust this value in production, or use tracesSampler for greater control
      tracesSampleRate: 1,

      // Setting this option to true will print useful information to the console while you're setting up Sentry.
      debug: false,

      replaysOnErrorSampleRate: 1.0,

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,

      // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    });
  });
}
export {};
